import React, { useContext, useEffect, useState } from 'react'
import Contact from '../components/header/contact'
import Resehdule from '../components/header/resehdule'
import Search from '../components/header/search'
import Translator from '../components/header/translator';
import ZendeskChat from '../components/header/zendeskChat';
import { UserContext } from "../context/context";
import { useLocation } from 'react-router-dom';

const initialContent = {
  phone:'918431328661',
  content: 'Hello Samskara, are you able to assist with a puja event booking ?!',
  text1: 'Muhuruth, Event Planning & Everything else.....',
  text2: 'Talk to our Puja consultant'
}
const PageSideMenu = () => {
  const [WAContent, setWAContent] = useState({
    ...initialContent
  });
  const { setLanguage, launchPopupViewed } = useContext(UserContext);
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [showLightPopup, setShowLightPopup] = useState(false);
  const [interval, setInt] = useState();
  const location = useLocation();

  const showPopup = () => {
    setTimeout(()=>{
      setShowSpeechBubble(true);
      let interval = setInterval(()=>{
        setShowLightPopup((showLightPopup)=> !showLightPopup);
      }, 5000);
      setInt(interval);
    }, 5000);
    clearInterval(interval);
    setInt();
    setShowLightPopup(false);

  }
  // useEffect(() => {
  //  if(!showSpeechBubble){
  //   showPopup();
  //  }
  // }, [location]);

  useEffect(()=>{
    if(location.pathname !== "/"){
      showPopup();
    }
    return ()=>clearInterval(interval);
  }, []);

  useEffect(()=>{
    if(launchPopupViewed && location.pathname === "/"){
      showPopup();
    }
  },[launchPopupViewed]);


  //Check if location path contains value
  useEffect(()=>{
    if(location.pathname.includes('consultancy-service')){
      setWAContent({
        ...initialContent,
        text1: 'Horoscope, Remedies, Vastu and more....',
        text2: 'Talk to our Astro consultant',
        phone: '8076184191'
      });
    }else{
      setWAContent({
       ...initialContent
      });
    }

  }, [location.pathname]);

  return (
    <>
    {showSpeechBubble && <div className={`speech-bubble-container ${showLightPopup && "light"}`}>
      <img className="img-fluid close-btn" src="/assets/images/accountcross.png" alt="image" onClick={()=>setShowSpeechBubble(false)} />
      <div className='speech-bubble'>
        <p className='speech-bubble-text'>{showLightPopup?WAContent.text1:WAContent.text2}</p>
      </div>
    </div>}
    <div className='floating-btn'>
      <a href={`https://wa.me/${WAContent.phone}?text=${encodeURIComponent(WAContent.content)}`} target="_blank">
        <img src="/assets/images/whatsappLogo.png" alt="image" className='wa-icon' />
      </a>
    </div>
      <div className="homeshivwrp">
        <ul>
          <li>
            {/* <span><i className="fa far fa-search"></i></span> */}
            <span>
              <img src="/assets/images/Search.png" alt="image" className='sideMenu' />
              <img src="/assets/images/SearchB.png" alt="image" />
            </span>
            <Search />
          </li>
          <li>
            <span>
              {/* <i className="fa far fa-phone-alt"></i> */}
              <img src="/assets/images/Phone.png" alt="image" className='sideMenu' />
              <img src="/assets/images/PhoneB.png" alt="image" />
            </span>
            <Resehdule />
          </li>
          {/* <li>
            <span >

              <img src="/assets/images/whatsappLogo.png" alt="image" className='sideMenu searchsideimgwrp' />
              <a href="https://api.whatsapp.com/send?phone=918431328661" target="_blank">
                <img src="/assets/images/whatsapp-blue.png" alt="image" className='searchsideimgwrp' />
              </a>

            </span>

          </li> */}

          <li>
            <span>
              {/* <i className="fa far fa-envelope"></i> */}
              <img src="/assets/images/Email.png" alt="image" className='sideMenu' />
              <img src="/assets/images/EmailB.png" alt="image" />
            </span>
            <Contact />
          </li>
          <li className='border-0'>


            <span >
              <img className='sideMenu searchsideimgwrp' src="/assets/images/languagewhite.png" alt="images" />
              <img className=' searchsideimgwrp ' src="/assets/images/languageblue.png" alt="images" />
            </span>
            <Translator />

          </li>
        </ul>
      </div>
    </>
  )
}

export default PageSideMenu